import React, { Component } from 'react';
import '../assets/css/stream.css';
import { Column, Columns } from "bloomer";
import CountDown from "./countdown.js";

class StreamClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu_active:false
    };
  }
  
  onMenuClick = () =>{
      this.setState({menu_active:!this.state.menu_active});
  }

  
  getType = ()=>{
    if(this.props.type !== undefined)
  	switch (this.props.type.toLowerCase()){
  		case 'chewtv':
  			return <ChewStream url={this.props.url} chat_url={this.props.chat_url} name={this.props.name} presenter={this.props.presenter}/>;
  		case 'youtube':
  			return <YouTubeStream url={this.props.url} chat_url={this.props.chat_url} name={this.props.name} presenter={this.props.presenter}/>;
  		case 'twitch':
  			return <TwitchStream url={this.props.url} chat_url={this.props.chat_url} name={this.props.name} presenter={this.props.presenter}/>;
  		case 'break':
  		case 'stop':
  		case 'switch':
  		  var text = this.props.description || this.props.name || "We're taking a quick break";
  			return <CountDown text={text} isText={true} playlist_link={this.props.playlist_link}/>  ;
  		default:
  			return <CountDown time={this.props.time} isText={false} force={true} playlist_link={this.props.playlist_link}/>  ;
  	}
  }
  
  render() {
    return (
        <div style={{'margin-bottom':'0px'}}>
          <div id="stream">
            {this.getType()}
          </div>
        </div>
    );
  }
}


class ChewStream extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu_active:false,
      cdn_id: 'ChewStreamCDN-'+Math.floor((Math.random() * 8))
    };
  }
  componentDidMount(){
    var script = document.createElement("script");

    script.src = "https://asset-cdn.chew.tv/js/embed.js";
    script.async = true;
    script.id = this.state.cdn_id;
    document.body.appendChild(script);
  }
  
  componentWillUnmount(){
    document.getElementById(this.state.cdn_id).remove();
  }
  
  onMenuClick = () =>{
      this.setState({menu_active:!this.state.menu_active});
  }
 render(){
   var url_params = this.props.url.includes('?')?'':'?autostart=true';
    return(
      <div className="stream">
        <nav>
          <div className={'menu-button '+(this.state.menu_active?'menu-active':'')} onClick={this.onMenuClick}></div>
          <ul className={'playlist '+(this.state.menu_active?'playlist-active':'')}>
            <iframe title="chewChat" src={this.props.chat_url} width="100%" style={{height:'100%'}} frameBorder="0"></iframe>
          </ul>
        </nav>
        <div className={'now-playing '+(this.state.menu_active ? 'now-playing-active':'')}>
          <iframe title="chewStream" className="chew" src={this.props.url+url_params} allow="autoplay" allowFullScreen={true} width="100%" style={{height:'100%'}} frameBorder="0"></iframe>
          <div className="overlay top"></div>
          <div className="overlay bottom"></div>
          <div className="info">
            <div className="small">Now Playing</div>
            <h2 id="currentShow">{this.props.name}</h2>
            <h3 id="currentDJ"><span>by: </span>{this.props.presenter}</h3>
          </div>
        </div>
      </div>
    );
  }
}

class TwitchStream extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu_active:false,
      cdn_id: 'TwitchStreamCDN-'+Math.floor((Math.random() * 8))
    };
  }
  onMenuClick = () =>{
      this.setState({menu_active:!this.state.menu_active});
  }
 render(){
   var url_params = this.props.url.includes('autoplay')?'':'&autoplay=true';
    return(
      <div className="stream">
        <nav>
          <div className={'menu-button '+(this.state.menu_active?'menu-active':'')} onClick={this.onMenuClick}></div>
          <ul className={'playlist '+(this.state.menu_active?'playlist-active':'')}>
            <iframe title="TwitchChat" src={this.props.chat_url} width="100%" style={{height:'100%'}} frameBorder="0"></iframe>
          </ul>
        </nav>
        <div className={'now-playing '+(this.state.menu_active ? 'now-playing-active':'')}>
          <iframe title="chewStream" className="chew" src={this.props.url+url_params} allow="autoplay" allowFullScreen={true} width="100%" style={{height:'100%'}} frameBorder="0"></iframe>
          <div className="overlay top"></div>
          <div className="overlay bottom"></div>
          <div className="info">
            <div className="small">Now Playing</div>
            <h2 id="currentShow">{this.props.name}</h2>
            <h3 id="currentDJ"><span>by: </span>{this.props.presenter} </h3>
          </div>
        </div>
      </div>
    );
  }
}


class YouTubeStream extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu_active:false,
      cdn_id: 'YouTubeStreamCDN-'+Math.floor((Math.random() * 8))
    };
  }
  onMenuClick = () =>{
      this.setState({menu_active:!this.state.menu_active});
  }
 render(){
   var url_params = this.props.url.includes('?')?'':'?autoplay=1&modestbranding=1&iv_load_policy=3';
    return(
      <div className="stream">
        <nav>
          <div className={'menu-button '+(this.state.menu_active?'menu-active':'')} onClick={this.onMenuClick}></div>
          <ul className={'playlist '+(this.state.menu_active?'playlist-active':'')}>
            <iframe title="YouTubChat" src={this.props.chat_url} width="100%" style={{height:'100%'}} frameBorder="0"></iframe>
          </ul>
        </nav>
        <div className={'now-playing '+(this.state.menu_active ? 'now-playing-active':'')}>
          <iframe title="YouTubeStream" className="youtubeStream" width="100%" style={{height:"100%"}} src={this.props.url+url_params} allow="autoplay; encrypted-media" allowFullScreen={true} frameBorder="0" scrolling="no"></iframe>
        <div className="overlay top"></div>
          <div className="overlay bottom"></div>
          <div className="info">
            <div className="small">Now Playing</div>
            <h2 id="currentShow">{this.props.name}</h2>
            <h3 id="currentDJ"><span>by: </span>{this.props.presenter} </h3>
          </div>
        </div>
      </div>
    );
  }
}

var Stream = StreamClass;
export default Stream
