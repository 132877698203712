import React, {Component} from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import cookie from 'react-cookies';

// Assets
import './assets/css/main.css';


// Components
import Header from './components/header.js';
import Footer from './components/footer.js';
import Cookiebar from './components/cookiebar.js';

// Pages
import Home from './pages/home.js';
import Schedule from './pages/schedule.js';

class App extends Component {
  
  // check if the user has already accepted the cookie bar
  componentWillMount = () => {
    this.setState({ 
      cookie_accepted: cookie.load('Cookie_Accepted'),
      cookie_bar_seen: cookie.load('Cookie_Accepted') || cookie.load('Seen_Cookie_Bar'),
      theme: cookie.load('Theme') || ''
    });
  }
  
  // once the cookie_bar has been accepted, remove it from the screen
  removeCookieBar = () => {
    this.setState({cookie_bar_seen:'true'});
  }
  
  render = () => {
    return (
      <Router>
        <React.Fragment>
          <Header />
          <Switch>
            <Route exact path='/' component={Home}/>
            <Route path='/home' component={Home}/>
            <Route path='/schedule' component={Schedule}/>
      	    <Route path="*" component={Home}/>
          </Switch>
           {this.state.cookie_bar_seen !== 'true'  ? <Cookiebar removal={this.removeCookieBar}/>:''}
          <Footer />
        </React.Fragment>
      </Router>

    );
  }
}

export default App;
