import React, { Component } from 'react';
import '../assets/css/tracktab.css';

class TrackTabClass extends Component {
  constructor(props) {
    super(props);
    console.log(this.props.tracks);
  }
  
  
  render() {
    return (
        <ul className="tabrow">
			{
			// make an tab for each track streaming.
			this.props.tracks?
			    this.props.tracks.map(item => (
			        this.props.currentTrack === item.track?
                        <li className="selected" onClick={e => this.props.onChangeTrack(item.track)}><span>Track {item.track}</span></li>
                    :
                        <li className="" onClick={ e => this.props.onChangeTrack(item.track)}><span>Track {item.track}</span></li>
                ))
                :
                ''
			}
        </ul>
    );
  }
}
var TrackTab = TrackTabClass;
export default TrackTab;
