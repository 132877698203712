import React, { Component } from 'react';
import '../assets/css/schedule.css';
import { Column, Columns } from "bloomer";

import SchedV from '../components/sched-v.js';
import SchedH from '../components/sched-h.js';
/*global fetch*/ // tell c9 that fetch already exists so it will stop marking the line with an error
    
class Home extends Component {
  constructor() {
    super();
    this.state = {
        schedule:[],
        layout:false
    };

  }
  
  
  componentDidMount() {
    // update start time, and get djs/supporters
     this.getSchedule();
  }
  
  getSchedule = () => {
    // Do an ajax call to get json of supporters
     fetch(window.location.origin+"/api/v1/programs")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            schedule: result.data
          });
          console.log(result.data);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({error});
        }
      );
  }
  
  changeLayout = () => {
    this.setState({
      layout:!this.state.layout
    });
  }
  
  // group an array of object by a given property
  //var arr = [
  //  {type:"orange", title:"First"},{type:"orange", title:"Second"},{type:"banana", title:"Third"},{type:"banana", title:"Fourth"}
  //];
  // var o = groupBy(arr, 'type'); // => {orange:[...], banana:[...]}
  //o.orange; // => [{"type":"orange","title":"First"},{"type":"orange","title":"Second"}]
  //o.banana; // => [{"type":"banana","title":"Third"},{"type":"banana","title":"Fourth"}]
  groupBy = (xs, prop) => {
    if (xs.length < 1){
      return [];
    }
    var grouped = [];
    for (var i=0; i<xs.length; i++) {
      var p = xs[i][prop];
      if (!grouped[p]) { grouped[p] = []; }
      grouped[p].push(xs[i]);
    }
    return grouped;
  }

  render() {
    return (
      <React.Fragment>
        {console.log("rendering:")}
        <div className="control checkbox-content">
          <i className="fa fa-th-large"></i>
          <label className="checkbox-switch">
				  	<input name="active" type="checkbox" defaultChecked={this.state.layout} onChange={this.changeLayout}/>
            <span className="slider round"></span>
          </label>
          <i className="fa fa-bars"></i>
        </div>
        {console.log(this.state.schedule)}
        { this.state.schedule.length !== 0?
        <section className="dark is-full-height section">
          <div className={this.state.layout?'':'columns is-multiline'}>
            {(this.groupBy(this.state.schedule, 'track')).map( x=> (this.state.layout?<SchedH track={x}/>:<SchedV track={x} />) )}
          </div>
        </section>
        :
        <Columns>
          <Column>
            <h3>Loading Schedule...<i class="fa fa-spinner fa-pulse fa-10x"></i></h3>
          </Column>
        </Columns>
        }
      </React.Fragment>
    );
  }
}

export default Home;
