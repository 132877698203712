import React, { Component } from 'react';
//import '../assets/css/sched.css';
import { Column } from "bloomer";
import '../assets/css/schedule-v.css';
var moment = require('moment-timezone');

class CountDownClass extends Component {
    
  constructor() {
    super();
    this.state = {
        count :0
    };
  }
 
  componentDidMount() {
      var count = 0;
      this.props.track.map( x=>( (x.type === 'break' || x.type === 'stop' || x.type === 'switch' )? '':count++ ) );
      this.setState({count:count});
  }
  
  render() {
    return (
        <React.Fragment>
            {
            this.state.count > 0?
            
                <Column>
                    <div class="schedule-card-v">
                        <div class="card-head">
                            Track {this.props.track[0].track}
                        </div>
                        <div class="card-inner">
                            {this.props.track.map( x=>  ( (x.type === 'break' || x.type === 'stop' || x.type === 'switch' )?  
                            '' 
                            : 
                            <div class={"schedule-list-item "+x.categories.split(',')[0]+' '+(moment(x.start_time).diff()>0?'':'past-time')}>
                                <div class="item-label pull-right">{x.categories}</div>
                                <div class="item-content pull-left">
                                    <h4>{moment(x.start_time).format('DD/MM hh:mm:ss a z')}</h4>
                                </div>
                                <div class="item-content pull-left">
                                    <h5>{x.presenter}</h5>
                                </div>
                                <div class="item-content pull-left">
                                    <h5><b>{x.name}</b></h5>
                                </div>
                                <div class="item-content pull-left">
                                    <h5>{x.description}</h5>
                                </div>
                            </div>
                            )
                            )}
                        </div>
                    </div>
                </Column>
            
            :
                <React.Fragment></React.Fragment>
            }
            
        </React.Fragment>
    );
  }
}
var CountDown = CountDownClass;
export default CountDown;