import React from 'react';
import '../assets/css/footer.css';
import { Icon, Footer, Button, Breadcrumb, BreadcrumbItem, Container, Column, Columns} from 'bloomer';
import { Follow, Timeline } from 'react-twitter-widgets';

class FooterClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      theme:this.props.theme||'light'
    };
  }
  
  render(){
    return (
  <Footer id="footer">
    <Container isFluid>
    <Columns isVCentered={true}>
      <Column isSize={{ mobile: 12, default: 12 }} hasTextAlign="centered">
        <h3>Want to get in touch with us?</h3>
        <p>Follow us on social media or email us.</p>
        <ul className="icons">
        	<li><a href="https://twitter.com/NeverLanCTF" className="icon fa fa-twitter fa-2x"><span className="label">Twitter</span></a></li>
        	<li><a href="https://www.facebook.com/NeverLANCTF" className="icon fa fa-facebook fa-2x"><span className="label">Facebook</span></a></li>
        	<li><a href="https://gitlab.com/NeverLAN-CTF" className="icon fa fa-gitlab fa-2x"><span className="label">Gitlab</span></a></li>
        	<li><a href="mailto:info@neverlanctf.com" className="icon fa fa-envelope fa-2x"><span className="label">Email</span></a></li>
        	<li><a href="https://github.com/NeverLAN-CTF" className="icon fa fa-github fa-2x"><span className="label">Github</span></a></li>
        </ul>
      </Column>
    </Columns>
    </Container>
    <Columns>
      <Column isSize='12'>
          <Breadcrumb isAlign="centered" isSize="small" hasTextAlign="centered" hasSeparator="dot">
            <ul>
              <BreadcrumbItem className="donate_container">
                <form action="https://www.paypal.com/cgi-bin/webscr" className="donate_form"  id="donate_form" method="post" target="_blank">
              		<input name="cmd" value="_donations" type="hidden"/>
              		<input name="business" value="paypal@neverlanctf.com" type="hidden"/>
              		<input name="lc" value="US" type="hidden"/>
              		<input name="item_name" value="NeverLan CTF" type="hidden"/>
              		<input name="currency_code" value="USD" type="hidden"/>
              		<input name="bn" value="PP-DonationsBF:btn_donate_SM.gif:NonHostedGuest" type="hidden"/>
              		<Button isColor='success'  isSize="small" onClick={function(){document.getElementById('donate_form').submit()}} >
              			<Icon isSize="small" className="fa fa-dollar" hasTextColor="white" />
                    <span>Donate</span>
              		</Button>
              	</form>
              </BreadcrumbItem>
              <BreadcrumbItem ><p style={{padding:'0 0.75em'}}>&copy;{new Date().getFullYear()} NeverLAN CTF Inc. All rights reserved.</p></BreadcrumbItem>
              <BreadcrumbItem isHidden="touch"><p  style={{padding:'0 0.75em'}}>Made with <Icon isSize="small" className="fa fa-heart" hasTextColor="danger" /> by The NeverLAN CTF Team</p></BreadcrumbItem>
              <BreadcrumbItem><a href='//neverlanctf.com/docs/code-of-conduct' className="has-text-light">Code of Conduct</a></BreadcrumbItem>
              <BreadcrumbItem><a href='//neverlanctf.com/docs/privacy-policy' className="has-text-light">Privacy</a></BreadcrumbItem>
              <BreadcrumbItem><a href='//neverlanctf.com/docs/terms-of-use' className="has-text-light">Terms</a></BreadcrumbItem>
            </ul>
        </Breadcrumb>
      </Column>
    </Columns>
  </Footer>
  );
  }
}

var Foot = FooterClass;

export default Foot;
