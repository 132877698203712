import React, { Component } from 'react';
import '../assets/css/carousel.css';

/*
 * TODO:
 *   - handle empty this.props.items for possible api failure
 *
 */

class ReelClass extends Component {
    constructor(props) {
        super(props);
        // variables
        this.speed = this.props.speed || 4;
        this.fadeIn = this.props.fadeIn || true;
        this.fadeDelay = this.props.fadeDelay || true;
        
        // never allow scrolling to far to the left
        this.leftLimit = 0;
        
        // generate a unique id for each carousel (and verify the id isn't taken)
        var id;
        do{
            id = Math.random().toString(36).substring(7)
        }while(document.getElementById('Reel-'+id))
        
        this.state = {
            // track how much the carousel has moved
            pos:0,
            // make id's for accessing elements in interval functions
            id: 'carousel-'+id,
            reel_id: 'Reel-'+id
        };
    }
    
    // set the right bound of the carousel. 
    updateDimensions = () =>{
        // get the width of the reel (this has all the elements so it may be longer screen)
        var width = document.getElementById(this.state.reel_id).scrollWidth;
        this.setState({
            // track how wide the reel is
            width:width,
            // set the right side limit to the end of the reel's width
            rightLimit: (document.body.clientWidth - width)
        });
        
    }
    
    componentDidMount() {
        // get width onload
        this.updateDimensions();
        // track page resizes and update width to match
        window.addEventListener("resize", this.updateDimensions);
    }
    
    componentWillUnmount() {
        // clear any scrolling event listeners
        this.cleanup();
        // stop tracking page resizes
        window.removeEventListener("resize", this.updateDimensions);
    }
  
    cleanup = () => {
        // stop the scrolling
        clearInterval(this.timerId);
    }

    forward = () =>{
        // keep `this` reference for timer function
        var t = this;
        // update the translate css class every .01 seconds
        this.timerId = window.setInterval(function() {
            // only allow scrolling right if we have't reached the end
            if (t.state.pos <= t.state.rightLimit){
                // stop trying to update if we're at the end
                window.clearInterval(t.timerId);
                // only say we are at the limit
                t.setState({pos : t.state.rightLimit});
            }else{
                // update position by scroll speed
                t.setState({pos:t.state.pos - t.speed});
            }
        }, 10);
    }
  
    backward = () =>{
        // keep `this` reference for timer function
        var t = this;
        // update the translate css class every .01 seconds
        this.timerId = window.setInterval(function() {
            // only allow scrolling left if we have't reached the end
            if (t.state.pos >= t.leftLimit){
                // stop trying to update if we're at the end
                window.clearInterval(t.timerId);
                // only say we are at the limit
                t.setState({pos : t.leftLimit});
            }else{
                // update position by scroll speed
                t.setState({pos:t.state.pos + t.speed});
            }
    	}, 10);
    }
  
  render() {
    return (
        <section className="carousel" id={this.state.id}>
    				<div className="reel" style={{ transform: 'translate('+this.state.pos+'px, 0px)'}} id={this.state.reel_id}>
    				{
    				// make an item for each element in the given list
    				this.props.items?
    				    this.props.items.map(item => (
                            <Item name={item.name} src={item.logo_url} href={item.website} text={item.text}/>
                        ))
                        :
                        ''
    				
    				}
    				</div>
    			<span className="forward" onMouseEnter={this.forward} onMouseLeave={this.cleanup} ></span>
    			<span className="backward" onMouseEnter={this.backward} onMouseLeave={this.cleanup} ></span>
    	</section>
    );
  }
}

// Items are the things in the carousel
class Item extends Component {
  constructor(props) {
    super(props);
    // get links, images, etc.
    const{ src, href, text, name} = this.props;
    this.src = src;
    this.href = href;
    this.text = text;
    this.name = name;
  }
  
  render() {
    return (
		<article className="">
			<a href={this.href} className="image featured"><img src={this.src} alt={this.name}/></a>
			<header>
				<h3><a href={this.href}>{this.name}</a></h3>
			</header>
			<p>{this.text}</p>
		</article>
    );
  }
}

var Carousel = ReelClass;
export default Carousel
