import React, { Component } from 'react';
import '../assets/css/cookiebar.css';
import cookie from 'react-cookies'

class CookiebarClass extends Component {
    
    // once they've accepted cookies, make a cookie so we won't ask again
    setAcceptedCookie = ()=>{
        // the cookie will be good for one year
        var exdays = 365;
        var d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        // set it to be good for any of our domains
        //var domain = window.location.href.split('/')[2].split(':')[0]; // for an ip address
        var domain = '.'+window.location.hostname.split('.').reverse().splice(0,2).reverse().join('.');
        // set the cookie
        cookie.save(
            'Cookie_Accepted',
            true,
            {
              path: '/',
              expires: d,
              domain: domain
            }
        );
        // finally remove the cookie bar
        // this function is given by app.js
        this.props.removal();
    }
    
        // once they've accepted cookies, make a cookie so we won't ask again
    setSeenCookie = ()=>{
        // the cookie will be good for one year
        var exdays = 365;
        var d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        // set it to be good for any of our domains
        //var domain = window.location.href.split('/')[2].split(':')[0]; // for an ip address
        var domain = '.'+window.location.hostname.split('.').reverse().splice(0,2).reverse().join('.');
        // set the cookie
        cookie.save(
            'Seen_Cookie_Bar',
            true,
            {
              path: '/',
              expires: d,
              domain: domain
            }
        );
        // finally remove the cookie bar
        // this function is given by app.js
        this.props.removal();
    }

  render = ()=>{
    return (
        <div className="cookie-container columns is-centered is-multiline is-mobile is-paddingless is-marginless" id="cookie-container">
            <div className="column is-paddingless is-10-mobile is-6-desktop">
                <p className="has-text-centered is-full-width">This website uses cookies. By continuing to browse the site you are agreeing to our use of cookies. Read our <a href="https://neverlanctf.com/docs/privacy-policy">Policy</a></p>
            </div>
            <div className="column is-paddingless is-full-mobile is-6-desktop">
                <div className="columns is-mobile is-centered is-paddingless is-marginless">
                    <div className="column is-4-mobile is-4-tablet">
                        <button id="cookie_accept" onClick={this.setAcceptedCookie}>Accept</button>
                    </div>
                    <div className="column is-4-mobile is-4-tablet">
                        <button id="noCookieBar" data-tooltip="We'll only set one cookie to ensure you won't see this bar again." onClick={this.setSeenCookie}>No, but get rid of this </button>
                    </div>
                    <div className="column is-4-mobile is-4-tablet">
                        <button id="decline" data-tooltip="We respect your decision, however, declining means you'll see this every time." onClick={this.props.removal}>Decline</button>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}
var Cookiebar = CookiebarClass;
export default Cookiebar

