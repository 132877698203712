import React, { Component } from 'react';
import { Link } from "react-router-dom";
import '../assets/css/navbar.css';
import {
  Navbar,
  NavbarBrand,
  NavbarItem,
  NavbarBurger,
  NavbarMenu,
  NavbarStart,
  NavbarEnd,
  NavbarLink,
  NavbarDropdown
} from 'bloomer';


// The Header creates links that can be used to navigate
// between routes.
class NavBarClass extends Component {
  constructor(props){
    super(props);
    // handle toggling of the mobile dropdown
    this.handleToggle = this.handleToggle.bind(this);
    // track if mobile nav is currently open or not
    this.state = {isActive: false};
  }
  
  // handle opening the mobile nav
  handleToggle(e){
	  this.setState({isActive: !this.state.isActive});
  }

  render() {
    return (
      <Navbar id="navbar" className="is-fixed-top">
        <NavbarBrand>
            <Link to="/" className="navbar-item">
              <img src="https://cdn.neverlanctf.com/icon.png" style={{ marginRight: 5 }} alt="NeverLAN CTF"/>
              NeverLAN CTF
            </Link>
            <NavbarBurger isActive={this.state.isActive} onClick={this.handleToggle} />
        </NavbarBrand>
        <NavbarMenu isActive={this.state.isActive} onClick={this.handleToggle}>
            <NavbarStart>
            </NavbarStart>
            <NavbarEnd>
                <NavbarItem href='https://neverlanctf.com'>Home</NavbarItem>
                <NavbarItem href='https://neverlanctf.com/register'>Register</NavbarItem>
                <NavbarItem hasDropdown isHoverable>
                    <NavbarLink href='#' className="is-arrowless">Music</NavbarLink>
                    <NavbarDropdown>
                        <Link to="/" className="navbar-item">Live Show</Link>
                        <Link to="/schedule" className="navbar-item">Schedule</Link>
                    </NavbarDropdown>
                </NavbarItem>
                <NavbarItem hasDropdown isHoverable>
                    <NavbarLink href='#' className="is-arrowless">Resources</NavbarLink>
                    <NavbarDropdown>
                        <NavbarItem href='https://neverlanctf.com/resources'>External Resources</NavbarItem>
                        <NavbarItem href='https://prectf.neverlanctf.com/'>Pre-CTF Challenges</NavbarItem>
                        <NavbarItem href='https://neverlanctf.com/supporters'>Supporters</NavbarItem>
                        <NavbarItem href='https://neverlanctf.com/media'>Media</NavbarItem>
                    </NavbarDropdown>
                </NavbarItem>
                <NavbarItem hasDropdown isHoverable>
                    <NavbarLink href='#' className="is-arrowless">About</NavbarLink>
                    <NavbarDropdown>
                        <NavbarItem href='https://neverlanctf.com/about'>The Event</NavbarItem>
                        <NavbarItem href='https://neverlanctf.com/creators'>The Creators</NavbarItem>
                        <NavbarItem href='https://neverlanctf.com/faq'>FAQs</NavbarItem>
                    </NavbarDropdown>
                </NavbarItem>
                <NavbarItem href='https://neverlanctf.com/contact'>Contact</NavbarItem>
            </NavbarEnd>
        </NavbarMenu>
      </Navbar>
      );
  }
}
var NavBar = NavBarClass;
export default NavBar
