import React, { Component } from 'react';
import '../assets/css/countdown.css';
import { Column, Columns } from "bloomer";
import MusicBar from './musicbar.js';

class CountDownClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      remaining: ''
    };
    this.tick = this.tick.bind(this);
  }
  
    componentDidMount() {
        this.timer = setInterval(this.tick, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }
  
    tick(){
        var end = new Date(this.props.time);

        var _second = 1000;
        var _minute = _second * 60;
        var _hour = _minute * 60;
        var _day = _hour * 24;
        var now = new Date();
        var distance = end - now;
        if( distance <= 0){
            clearInterval(this.timer);
            if(this.props.force !== true){
                this.props.onZero();
            }
            return;
        }
        var days = Math.floor(distance / _day);
        var hours = Math.floor((distance % _day) / _hour);
        var minutes = Math.floor((distance % _hour) / _minute);
        var seconds = Math.floor((distance % _minute) / _second);
        this.setState({remaining:days +':'+hours+':'+minutes+':'+seconds});
    }

  
  render() {
    return (
        <div id="section-countdown">
            <MusicBar playlist_link={this.props.playlist_link}/>
            <div>
                <div>
                    <h3 className="count-text is-size-1 is-size-3-mobile">
                        <a href="https://neverlanctf.com">NeverLAN Music Countdown</a>
                    </h3>
                </div>
            </div>
            <div>
                {this.props.isText?
                <div>
                    <div id="countdown" className="animated hue" align="center"><p className="is-size-1 is-size-2-mobile">{this.props.text}</p></div>
                </div>
                :
                <div className="clock">
                    <div id="countdown" className="animated hue" align="center"><p className="is-size-1 is-size-2-mobile">{this.state.remaining}</p></div>
                </div>
                }
            </div>
        </div>
    );
  }
}
var CountDown = CountDownClass;
export default CountDown
