import React, { Component } from 'react';
//import '../assets/css/sched.css';
import { Tile } from "bloomer";
import '../assets/css/schedule-h.css';
var moment = require('moment-timezone');

class CountDownClass extends Component {
    
  constructor() {
    super();
    this.state = {
        count :0
    };
  }
 
  componentDidMount() {
      var count = 0;
      this.props.track.map( x=>( (x.type === 'break' || x.type === 'stop' || x.type === 'switch' )? '':count++ ) );
      this.setState({count:count});
  }
  
  render() {
    return (
        <React.Fragment>
            {
            this.state.count > 0?
                <Tile isAncestor isVertical className="schedule-h">
                    <Tile isParent isSize={12} className="card-head">
                        <div className="card-title">Track {this.props.track[0].track}</div>
                    </Tile>
                    <Tile isParent isSize={12} className="schedule-card-h">
                            {this.props.track.map( x=>  ((x.type === 'break' || x.type === 'stop' || x.type === 'switch' ) ?  
                            '' 
                            : 
                            <Tile isChild className="card-inner">
                                <div class={"schedule-list-item "+x.categories.split(',')[0]+' '+(moment(x.start_time).diff()>0?'':'past-time')}>
                                    <div class="item-label pull-right">{x.categories}</div>
                                    <div class="item-content pull-left">
                                        <h4>{moment(x.start_time).format('DD/MM hh:mm:ss a z')}</h4>
                                    </div>
                                    <div class="item-content pull-left">
                                        <h6>{x.presenter}</h6>
                                    </div>
                                    <div class="item-content pull-left">
                                        <h4><b>{x.name}</b></h4>
                                    </div>
                                    <div class="item-content pull-left">
                                        <h5>{x.description}</h5>
                                    </div>
                                </div>
                            </Tile>
                            )
                            )}
                    </Tile>
                </Tile>
            :
                <React.Fragment></React.Fragment>
            }
        </React.Fragment>
    );
  }
}
var CountDown = CountDownClass;
export default CountDown;