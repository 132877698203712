import React, { Component } from 'react';
import { Columns, Column } from "bloomer";


class MusicBarClass extends Component {
  render() {
    return (
      <div>
        <div>
            <iframe title="music widget" src={this.props.playlist_link} width="100%" height="80px" frameBorder="0"/>
        </div>
      </div>
    );
  }
}
var MusicBar = MusicBarClass;
export default MusicBar
