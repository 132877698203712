import React, { Component } from 'react';
import { Link } from "react-router-dom";
import '../assets/css/home.css';
import io from 'socket.io-client';
import { Button } from "bloomer";
import alertify from 'alertifyjs';

// Components
import CountDown from '../components/countdown.js';
import Stream from '../components/stream.js';
import Carousel from '../components/carousel.js';
import TrackTab from '../components/tracktab.js';

// setup websocket
var socket = io('/stream');

/*global fetch*/ // tell c9 that fetch already exists so it will stop marking the line with an error
    
class Home extends Component {
  constructor() {
    super();
    this.state = {
      // the json of supporters (will be filled by api request)
      supporters: [],
      // the json of djs (will be filled by api request)
      djs: [],
      // the tracks to show in the tracks tab
      tracks: [],
      // This will hold the stream object (Name, link, presentor, etc.)
      stream: null,
      // The time will be updated through the websocket, but to keep from showing the stream onload, we'll set this to some time in the future
      time_to_stream:"1/01/9999 1:00:00 AM MST",  // sometime in the future
      // For the music bar, we need a playlist to use (will be updated by api request)
      playlist_link:'https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&feed=%2Fs7a73farm%2Fneverlan-ctf-2018-live-mix-feat-s7a73farm-metacortex%2F',
      // This is mostly for debugging. 
      // This will force the showing of the countdown
      force_countdown:false
    };
    // the track we are watching
    this.track=1;

    // recieve events
    
    // when we recieve a new stream object, update the state.
    socket.on('update_stream', ({stream}) => {
      if (stream && stream.type === 'switch'){  		  
        // look for the track number to switch to in this order:
  		  // the url field
  		  // the chat_url field
  		  // the description field
  		  // default to track 1
        var t = stream.url&&!isNaN(stream.url)?stream.url:
          stream.chat_url&&!isNaN(stream.chat_url)?stream.chat_url:
            stream.description&&!isNaN(stream.description)?stream.description:1;
        // we must use integer track numbers. For JS's sake, not the servers.
        this.switchTrack(parseInt(t, 10));
        return;
      }
      this.setState({stream});
      //console.log(stream);
    });
    // when we recieve a new time to stream at, update the countdown.
    socket.on('update_time', ({time}) => {
      this.setState({time_to_stream:time});
      //console.log(time);
    });
    // when we recieve a new set of tracks, update the track tab
    socket.on('update_tracks', ({tracks}) => {
      // If we are just getting the tracks, or
      // If the track we are watching no longer exists,
      // switch to the first track available
      if(this.state.tracks.length === 0 || !this.trackExists(this.track, tracks)){
        this.switchTrack(tracks[0].track);
      }
      this.setState({tracks:tracks});
      
      //console.log(tracks);
    });
    // If the server tells us to switch tracks, do so.
    socket.on('switch_track', ({track}) => {
      //console.log("switching to track:");
      //console.log(track);
      this.switchTrack(track);
    });
    // If the server tells us to refresh, do so.
    socket.on('refresh', () => {
      this.updateTime();
      this.getStream();
      this.getTabs();
    });
    // If the server tells us to disconnect, do so.
    socket.on('disconnect', reason => {
      var that = this;
      //console.log("recieved disconnect request.");
      if(reason === 'io server disconnect') {
        //console.log('server told me to.');
      }else if(reason === 'transport error' || reason === 'transport close'){
        //console.log('Server cannot be reached.');
        setTimeout(function(){
          //console.log('attempting to reconnect');
          socket.connect();
          that.switchTrack(that.track);
        },10000);
      }else if(reason === 'io client disconnect'){
        //console.log('server told me to.');
      }else{
        //console.log(reason);
      }
      this.disconnect();
      socket.disconnect();
    });
    // If we get a notification from the server, lets show it.
    socket.on('notification', ({message, type, wait})=>{
      alertify.set('notifier','position', 'top-center');
      alertify.notify ( message, type, wait);
    });
  }
  
  
  disconnect = () => {
    //console.log("disconnecting...");
    socket.send('disconnect');
  }
  
  
  componentDidMount() {
    // update start time, and get djs/supporters
     socket.emit('time_update', {'track':this.track});
     socket.emit('tracks_update');
     this.getSupporters();
     this.getDjs();
     this.getMusicBar();
     // add some clean up code
     window.addEventListener('beforeunload', this.disconnect);
  }
  
  componentWillUnmount() {
    this.disconnect();
    window.removeEventListener('beforeunload', this.disconnect); // remove the event handler for normal unmounting
  }
  
  getSupporters = () => {
    // Do an ajax call to get json of supporters
     fetch(window.location.origin+"/api/v1/supporters/event")
      .then(res => res.json())
      .then(
        (result) => {
          if(result.hasOwnProperty('data')){
            this.setState({
              supporters: result.data
            });
          }
          //console.log(result.data);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({error});
        }
      );
  }
  
  getDjs = () => {
    // Do an ajax call to get json of Djs
    fetch(window.location.origin+"/api/v1/djs/event")
    .then(res => res.json())
    .then(
      (result) => {
        if(result.hasOwnProperty('data')){
          this.setState({
            djs: result.data
          });
        }
        //console.log(result.data);
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        this.setState({error});
      }
    );
  }
  
  
  switchTrack = (track) => {
    if(track !== this.track){
      this.track = track;
      socket.emit('switch_track', {'track':track});
      this.updateTime();
      this.getStream();
    }
  }
  
  
  // get latest time to stream
  updateTime = () => {
    socket.emit('time_update', {'track':this.track});
    this.setState({force_countdown:false});
  }
  
  // we can tell the server to send an update for the stream
  getStream = (track) => {
    socket.emit('stream_update', {'track':this.track});
    this.setState({force_countdown:false});
  }
  
  // we can tell the server to send an update for the track tab
  getTabs = (track) => {
    socket.emit('tracks_update');
  }

  // Force the countdown to show.
  leaveStream = () => {
    this.setState({stream:null, force_countdown:true});
    //socket.emit('stream-remove', {'track':this.track});
  }
  
  // Update the music bar link.
  getMusicBar = () => {
    fetch(window.location.origin+"/api/v1/options/MusicBarLink")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            playlist_link: result.data.value
          });
          //console.log(result.data);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({error});
        }
      );
  }
 
  // Check if the current track exists in the known tracks
  trackExists = (track, tracks) => {
    if(!tracks){
      tracks = this.tracks;
    }
    for (var i = 0 ; i < tracks.length; i++){
      if(tracks[i].track === track){
        return true;
      }
    }
    return false;
  }
  
  render() {
    return (
      <React.Fragment>
          {
            // only show the stream if we have a stream object
            this.state.stream === null || this.state.stream.type === '' ? (
                <CountDown onZero={this.getStream} time={this.state.time_to_stream} isText={false} force={this.state.force_countdown} playlist_link={this.state.playlist_link}/> 
              ) : (
                <Stream url={this.state.stream.url} name={this.state.stream.name}  description={this.state.stream.description} presenter={this.state.stream.presenter} chat_url={this.state.stream.chat_url} type={this.state.stream.type} playlist_link={this.state.playlist_link} time={this.state.time_to_stream}/>
              )
          }
          <section className="dark section is-paddingless">
            {
              // only show the tracks tab if there is more than one track
              this.state.tracks.length < 2 ? '' : (
                <TrackTab tracks={this.state.tracks} currentTrack={this.track} onChangeTrack={this.switchTrack}/>
                )
            }
            
            
            {
              this.state.stream !== null && this.state.stream.type !== '' ? (
                
                  <div className="columns is-centered is-mobile is-marginless">
                    <div className="column is-10 is-full-mobile">
                      <h2 className="title has-text-centered">{this.state.stream.name}</h2>
                      <p className="subtitle has-text-justified">{this.state.stream.description}</p>
                    </div>
                  </div>
              ) : ('')
              
            }
          </section>
          { 
          this.state.supporters.length > 0 ?(
          <div>
            <section className="banner">
  				  	<header>
  				  		<h2 className="title">Our Awesome Supporters</h2>
  				  	</header>
			  	  </section>
            <Carousel items={this.state.supporters}/>
            </div>
          ) : ''
            
          }
          { 
          this.state.djs.length > 0 ?(
            <div>
              <section className="banner">
  				    	<header>
  				    		<h2 className="title">DJ Line-Up</h2>
  				    	</header>
			  	    </section>
              <Carousel items={this.state.djs}/>
            </div>
          ) : ''
            
          }
      </React.Fragment>
    );
  }
}

export default Home;
