import React from 'react';
import Navbar from "./navbar.js";

class HeaderClass extends React.Component {
  render(){
    return (
        <Navbar/>
    );
  }
}

var Header = HeaderClass;

export default Header;